/* Global Styles */
body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    background-color: #f0f2f5;
    font-family: Arial, sans-serif;
}

/* Main Block Styles */
.main-block {
    background-color: #ffffff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 100%;
    max-width: 400px;

    h2 {
        margin-bottom: 20px;
        color: #333333;
    }

    form {
        display: flex;
        flex-direction: column;

        div {
            margin-bottom: 15px;
            text-align: left;
        }

        label {
            margin-bottom: 5px;
            font-weight: bold;
        }

        input {
            width: 100%;
            padding: 10px;
            border: 1px solid #ddd;
            border-radius: 5px;
            box-sizing: border-box;
        }

        button[type="button"] {
            background: none;
            border: none;
            color: #333333;
            cursor: pointer;
            font-size: 1.2em;
            position: absolute;
            right: 10px;
            z-index: 1;

            &:hover {
                color: black;
            }
        }

        button[type="submit"] {
            padding: 10px;
            background-color: #28a745;
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            font-size: 1em;

            &:hover {
                background-color: #218838;
            }
        }
    }

    p {
        margin-top: 20px;
    }

    a {
        color: #007bff;
        text-decoration: none;
    }

    .crud {
        display: flex;
        justify-content: space-around;

        button[type="submit"] {
            margin-top: 1vh;
            padding: 10px;
            background-color: #e62929;
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            font-size: 1em;

            &:hover {
                background-color: #ab2b24;
            }
        }
    }
}

/* Password Input Styles */
.password-input-container {
    display: flex;
    align-items: center;
    position: relative;
}

.watermark {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 40px;
    height: 40px;
    margin-right: 2%;
    margin-bottom: 2%;
}
.watermark img {
    width: 100%;
    height: auto;
}